import VisaoBasica from "../components/visao-basica/VisaoBasica";

export default function Home() {
    return (
        <VisaoBasica menuAtivo="Home">
            <div className="container">
                <h2>Olá, mundo!</h2>
            </div>
        </VisaoBasica>
    );
}