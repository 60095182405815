import useModal from "../../modal/hooks/useModal";
import MainMenuContent from "./MainMenuContent";

interface IMainMenuProps {
    ativo: string;
}

export default function MainMenu({ ativo }: IMainMenuProps) {

    const { HandleOpenModal } = useModal();

    const OpenMenu = () => {
        HandleOpenModal(<MainMenuContent ativo={ativo} />, "move")
    }

    return (
        <button onClick={OpenMenu} className=""><i className="icofont-duotone icofont-menu"></i></button>
    );
}