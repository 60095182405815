import { useEffect, useRef } from "react";
import useTheme from "../../../hooks/useTheme";
import QuickSearch from "../../QuickSearch/QuickSearch";
import Logo from "../../logo/Logo";
import MainMenu from "../main-menu/MainMenu";

interface IHeaderProps {
    menu: string;
}

export default function Header({ menu }: IHeaderProps) {

    const { setThemeController, ScrollState } = useTheme();
    const header = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        setThemeController('landing');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        switch (ScrollState) {
            case 'initial':
                if (header.current) header.current.removeAttribute('data-stuck');
                break;

            case 'scrolling':
                if (header.current) header.current.setAttribute('data-stuck', '');
                break;
        }
    }, [ScrollState]);

    return (
        <header ref={header} className="header">
            <div className="header-wrapper">
                <div className="header-container">
                    <QuickSearch />
                    <Logo />
                    <MainMenu ativo={menu} />
                </div>
            </div>
        </header>
    );
}