import { useEffect, useRef } from "react";
import { Post } from "../../models/Post";
import ArticleInfo from "../widgets/article-info/ArticleInfo";
import TableOfContents from "../widgets/table-of-contents/TableOfContents";

interface IPostSingleProps {
    post: Post;
}

export default function PostSingle({ post }: IPostSingleProps) {

    const widgets = useRef<HTMLDivElement>(null);

    const fixOnScroll = () => {
        if(widgets.current) {
            if(window.scrollY > 100) {
                widgets.current.classList.add('fixed');
                widgets.current.style.top = "104px";
            } else {
                widgets.current.classList.remove('fixed');
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', fixOnScroll);
    }, []);

    return (
        <div className="post-single">
            <div className="d-flex">
                <div className="post-widgets col-4">
                    <div ref={ widgets } className="post-widgets-container">
                        <ArticleInfo post={post} />
                        <TableOfContents />
                    </div>
                </div>
                <div className="col-8">
                    <div className="post">
                        <div className="post-title"><h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h1></div>
                        <div className="post-publish-date"><i className="fa fa-calendar"></i><span><b>Published:</b>{ post.date.toString() }</span></div>
                        <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
                        { post.categories ? (
                            <div className="post-categories-meta">
                                <span><b>Categorized in:</b> { post.categories.map( (category, index) => `${category.name} ${ post.categories!.length - 1 !== index  ? ', ' : ''}` ) } </span>
                            </div>
                        ) : '' }
                        <div className="post-share-meta">
                            <span>Share Article:</span>
                            <ul>
                                <li><i className="fa-brands fa-facebook"></i></li>
                                <li><i className="fa-brands fa-twitter"></i></li>
                                <li><i className="fa fa-envelope"></i></li>
                                <li><i className="fa-brands fa-whatsapp"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}