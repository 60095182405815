import { ReactNode } from "react";
import './_centralizador.scss';

interface ICentralizadorProps {
    children: ReactNode;
}


export default function Centralizador({ children }: ICentralizadorProps) {
    return (
        <div className="centralizador">{ children }</div>
    );
}