import { Post } from "../../../models/Post";
import Dificuldade from "../../dificuldade/Dificuldade";

interface IArticleInfoProps {
    post: Post;
}

export default function ArticleInfo({ post }: IArticleInfoProps) {
    return (
        <div className="article-info">
            <h4 className="article-info-title"><i className="fa-solid fa-bolt"></i>Article Information</h4>
            <div className="article-info-container">
                <ul>
                    <li><i className="fa fa-tag"></i><span><b>Category:</b>[category_name]</span></li>
                    <li><i className="fa fa-clock"></i><span><b>Updated:</b>[post_last_update]</span></li>
                    <li><i className="fa fa-user"></i><span><b>Author:</b>[post_author_name]</span></li>
                    <li><i className="fa fa-stopwatch"></i><span><b>Reading time:</b>[post_reading_time]</span></li>
                    { post.dificuldade ? <li><Dificuldade nivel={ parseInt(post.dificuldade) } label /></li> : '' }
                </ul>
            </div>
        </div>
    );
}