import { useEffect, useState } from "react";

interface Ancora {
    title: string;
    link: string;
    position: number;
    element: Element;
}

export default function TableOfContents() {

    const [ancoras, setAncoras] = useState<Ancora[]>([]);
    const [ancoraAtiva, setAncoraAtiva] = useState<string>('');

    useEffect(() => {
        let data:Ancora[] = [];
        const dados = document.querySelectorAll('.wp-block-heading a');

        if(dados.length === 0) return;
        
        dados.forEach( ancora => data.push({
            title: ancora.textContent!,
            link: ancora.getAttribute('href')!,
            position: ancora.getBoundingClientRect().top,
            element: ancora
        }));
        setAncoras(data);
        setAncoraAtiva(data[0].title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {        
        window.addEventListener('scroll', changeAnchorActive);
        return () => window.removeEventListener('scroll', changeAnchorActive);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ancoras]);

    const changeAnchorActive = (e: any) => {
        const scrollPosition = window.scrollY + 105;
        let minDistance = 80;

        ancoras.map( ancora => {
            let distance = Math.abs(ancora.position - scrollPosition);

            if (distance < minDistance) {
                setAncoraAtiva(ancora.title);
            }

            return 0;
        });
    }

    const navigateToAnchor = (element: Ancora) => {
        setAncoraAtiva(element.title);
        scrollTo(element.position);
    }

    const scrollTo = (y: number) => {
        window.scrollTo({
            top: y - 100,
            behavior: 'smooth'
        });
    }

    if(ancoras.length === 0) return <></>;

    return (
        <div className="table-of-contents">
            <h4 className="table-of-contents-title"><i className="fa-solid fa-bolt"></i>Table of Contents</h4>
            <div className="table-of-contents-container">
                <ul>
                    { ancoras.map( ancora => (
                        <li 
                            key={ancora.title}
                            className={`${ ancoraAtiva === ancora.title ? 'ativo' : '' }`}
                            onClick={e => navigateToAnchor(ancora)}
                        ><a href={ ancora.link }>{ ancora.title }</a></li>
                    )) }
                </ul>
            </div>
        </div>
    );
}