import './_footer.scss';

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <h1>Olá, footer!</h1>
            </div>
        </footer>
    );
}