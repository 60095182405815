import { useNavigate } from "react-router-dom";
import useTheme from "../../../hooks/useTheme";
import Logo from "../../logo/Logo";
import useModal from "../../modal/hooks/useModal";

interface IMainMenuContentProps {
    ativo: string;
}


export default function MainMenuContext({ ativo }: IMainMenuContentProps) {

    const { HandleCloseModal } = useModal();
    const { ToggleThemeButton } = useTheme();

    const navigate = useNavigate();


    const HandleNavigate = (rota: string) => {
        navigate(rota);
        HandleCloseModal();
    }

    return (
        <div className="main-menu-content">        
            <ToggleThemeButton />
            <div className="btn-fechar" onClick={HandleCloseModal}><i className="fa fa-times"></i></div>
            <Logo />
            <nav className="main-menu-nav">
                <ul>
                    <li className={`${ ativo === 'Home' ? 'ativo' : '' }`} onClick={ () =>  HandleNavigate('/')}>Home</li>
                    <li className={`${ ativo === 'Blog' ? 'ativo' : '' }`}  onClick={ () =>  HandleNavigate('/blog')}>Blog</li>
                    <li className={`${ ativo === 'Links' ? 'ativo' : '' }`}  onClick={ () =>  HandleNavigate('/links')}>Links</li>
                    {/* <li className={`${ ativo === 'Contact' ? 'ativo' : '' }`}  onClick={ () =>  HandleNavigate('/')}>Contact</li> */}
                </ul>
            </nav>
            <button className="btn btn-primary">Subscribe</button>
        </div>
    );
}