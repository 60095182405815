import { useNavigate } from "react-router-dom";
import PublicImage from "../basic-ui/PublicImage";
import useModal from "../modal/hooks/useModal";

export default function Logo() {

    const navigate = useNavigate();
    const { HandleCloseModal } = useModal();

    const HandleGoToHome = (e: any) => {
        e.preventDefault();

        HandleCloseModal();
        navigate('/');
    }

    return (
        <div className="logo" onClick={ HandleGoToHome }>
            <PublicImage src="user_image.png" />
            <h2 className="title-logo">ofernandoavila</h2>
        </div>
    );
}