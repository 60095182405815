import { ReactNode } from "react";
import Modal from "../modal/Modal";
import Header from "../landing-page/header/Header";

import './_visao-basica.scss';
import Footer from "../footer/Footer";

interface IVisaoBasicaProps {
    menuAtivo: string;
    children: ReactNode;
}

export default function VisaoBasica({ children, menuAtivo }: IVisaoBasicaProps) {
    return (
        <>
            <Modal />
            <div id="landing-page">
                <Header menu={ menuAtivo }/>
                <main>
                { children }
                </main>
                <Footer />
            </div>
        </>
    );
}