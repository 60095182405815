import { useEffect, useState } from "react";
import './_dificuldade.scss';

interface IDificuldadeProps {
    nivel: number;
    label?: boolean;
}

export default function Dificuldade({ nivel, label }: IDificuldadeProps) {
    const [estrelas, setEstrelas] = useState<JSX.Element[]>([]);

    const render = () => {
        let out: JSX.Element[] = [];
        for(let i = 0; i < 3; i++) {
            if(nivel - i > 0) {
                out.push(<i key={ i + nivel } className="fa fa-star"></i>);
            } else {
                out.push(<i key={ i + nivel } className="fa-regular fa-star"></i>);
            }
        }
        setEstrelas(out);
    }

    useEffect(() => {
        render();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nivel]);

    if (label) return <div className="meta-dificulty"><i className="fa fa-award"></i><span><b>Dificulty:</b>{ estrelas.map( estrela => estrela ) }</span></div>;

    return (
        <div className="meta-dificulty">
            <i className="fa fa-award"></i>
            <span>{ estrelas.map( estrela => estrela ) }</span>
        </div>
    );
}