import { useEffect, useState } from "react";
import PostCard from "../components/post-card/PostCard";
import { Post } from "../models/Post";
import API from "../api/service/API";
import VisaoBasica from "../components/visao-basica/VisaoBasica";
import Loading from "../components/loading-wheel/Loading";
import Centralizador from "../components/centralizador/Centralizador";

export default function Blog() {    
    const [posts, setPosts] = useState<Post[] | null>(null);
    
    useEffect(() => {
        const api = new API();

        api.getPosts()
            .then( data => setPosts(data));

    }, []);

    return (
        <VisaoBasica menuAtivo="Blog">
            <div id="home" className="container">
            { posts ? (
                <ul className="posts">
                    { posts.map( post => (
                        <li key={ post.id }><PostCard post={ post }/></li>
                    ) ) }
                </ul>
            ) : (
                <Centralizador><Loading /></Centralizador>
            ) }
            </div>
        </VisaoBasica>
    );
}