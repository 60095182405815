import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../api/service/API";
import { Post as PostModel } from "../models/Post";
import PostSingle from "../components/post-single/PostSingle";
import VisaoBasica from "../components/visao-basica/VisaoBasica";
import Centralizador from "../components/centralizador/Centralizador";
import Loading from "../components/loading-wheel/Loading";

export default function Post() {

    const { id } = useParams();

    
    const [post, setPost] = useState<PostModel | null>(null);
    
    useEffect(() => {
        const api = new API();

        if(id) {
            api.getPostById(parseInt(id))
                .then( data => setPost(data));
        }

    }, [id]);
        
    return (
        <VisaoBasica menuAtivo="Post">
            <div className="container">
                { post ? (
                    <PostSingle post={post} />
                ) : (
                    <Centralizador><Loading /></Centralizador>
                ) }
                
            </div>
        </VisaoBasica>
    );
}